


































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { FormItem, Input, Radio, RadioGroup, RadioButton } from "element-ui";
@Component({
    components: {
        "el-form-item": FormItem,
        "el-input": Input,
        "el-radio-group": RadioGroup,
        "el-radio": Radio,

        addEditor(resolve) {
            require(["@/components/editor/Editor.vue"], resolve);
        },

        addInput(resolve) {
            require(["@/components/sign/FormItemInput.vue"], resolve);
        },

        addSelector(resolve) {
            require(["@/components/sign/FormItemSelect.vue"], resolve);
        },

        addRadio(resolve) {
            require(["@/components/sign/FormItemRadio.vue"], resolve);
        },

        addCheckbox(resolve) {
            require(["@/components/sign/FormItemCheckbox.vue"], resolve);
        },

        addUploadImg(resolve) {
            require(["@/components/sign/FormItemUpimg.vue"], resolve);
        },

        addUploadFile(resolve) {
            require(["@/components/sign/FormItemUpfile.vue"], resolve);
        },

        addDatePicker(resolve) {
            require(["@/components/sign/FormItemDatePicker.vue"], resolve);
        },

        addContent(resolve) {
            require(["@/components/sign/FormItemContent.vue"], resolve);
        }
    }
})
export default class FormItemBox extends Vue {
    @Prop({ type: Object, required: true, default: () => ({}) })
    formData!: any; //表单数据

    @Prop({ type: Object, required: true, default: () => ({}) })
    formItemOpt!: any; //表单数据

    @Prop({ type: String, required: true, default: () => ({}) })
    formkey!: any; //表单项唯一标识

    @Prop({ type: String, required: true, default: () => ({}) })
    formName!: any; //表单ref

    @Prop({
        type: String,
        required: false,
        default: () => {
            return undefined;
        }
    })
    label?: any; //表单项label

    @Prop({ type: [String, Object], required: false, default: () => ({}) })
    placeholder: any; //表单项placeholder

    @Prop({ type: String, required: true, default: () => ({}) })
    type!: any; //表单项类别

    @Prop({ type: [Array, Object], required: false, default: () => ({}) })
    children!: any; //表单项类别

    @Prop({ type: [Object, Array], required: false, default: () => ({}) })
    rules: any; //表单项类别

    component = {
        /*
            name:异步import组件的方法名,
            id:组件id,
            ref:组件ref,
            childName:子组件ref 调用子组件方法时传参获得子组件,
        */
        currentComponent: "",
        customer_id: "",
        customer_ref: ""
    };

    //当editor值改变时，改变隐藏的textarea的值
    changeEditorValue(data: any) {
        if (this.formItemOpt.type == "editor") {
            this.formData[this.formItemOpt.formkey] = data;
        }
    }

    onblur(el: any) {
        this.$emit(
            "valid",
            this.formName,
            this.formItemOpt.formkey,
            (res: any) => {}
        );
    }

    validateItem(el: any) {
        this.$emit(
            "valid",
            this.formName,
            this.formItemOpt.formkey,
            (res: any) => {
                // console.log(res);
            }
        );
    }

    onchange(data: any, callback?: any) {
        if (this.formItemOpt.type == "uploadfile") {
            let key = this.formData[this.formItemOpt.formkey].push(data) - 1;
            // console.log(this.formData[this.formItemOpt.formkey]);
            // console.log(length)
            callback ? callback(key) : false;
        } else {
            this.formData[this.formItemOpt.formkey] = data;
        }
    }

    //改变进度条值
    onChangeProgress(data: any, filekey: number, callback?: any) {
        this.formData[this.formItemOpt.formkey][filekey].progress = data;
    }

    onChangeGiveid(id: any, filekey: number) {
        this.formData[this.formItemOpt.formkey][filekey].id = id;
    }

    makeWatch() {
        this.$watch(
            () => {
                return this.formData[this.formItemOpt.formkey];
            },
            (nval: any, oval: any) => {
                // console.log(this.$refs.formEditor)
            }
        );
    }

    mounted() {
        if (this.formItemOpt.type == "editor") {
            this.component.currentComponent = "addEditor";
            this.component.customer_id = "formEditor";
            this.component.customer_ref = "formEditor";
            // this.makeWatch();
        } else if (
            this.formItemOpt.type == "input" ||
            this.formItemOpt.type == "password" ||
            this.formItemOpt.type == "textarea"
        ) {
            this.component.currentComponent = "addInput";
            this.component.customer_id = "formInput";
            this.component.customer_ref = "formInput";
        } else if (this.formItemOpt.type == "selector") {
            this.component.currentComponent = "addSelector";
            this.component.customer_id = "formSelect";
            this.component.customer_ref = "formSelect";
        } else if (this.formItemOpt.type == "radio") {
            this.component.currentComponent = "addRadio";
            this.component.customer_id = "formRadio";
            this.component.customer_ref = "formRadio";
        } else if (this.formItemOpt.type == "checkbox") {
            this.component.currentComponent = "addCheckbox";
            this.component.customer_id = "formCheckbox";
            this.component.customer_ref = "formCheckbox";
        } else if (this.formItemOpt.type == "uploadimg") {
            this.component.currentComponent = "addUploadImg";
            this.component.customer_id = "formUploadImg";
            this.component.customer_ref = "formUploadImg";
        } else if (this.formItemOpt.type == "uploadfile") {
            this.component.currentComponent = "addUploadFile";
            this.component.customer_id = "formUploadFile";
            this.component.customer_ref = "formUploadFile";
        } else if (this.formItemOpt.type == "datePicker") {
            this.component.currentComponent = "addDatePicker";
            this.component.customer_id = "formDatePicker";
            this.component.customer_ref = "formDatePicker";
        } else if (this.formItemOpt.type == "content") {
            this.component.currentComponent = "addContent";
            this.component.customer_id = "formContent";
            this.component.customer_ref = "formContent";
        }
    }

    destroyed() {
        // clearTimeout(this.timer);
        // this.timer = "";
    }
}
